import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'

export enum IssuingEntityType {
  state = 'issuing_state',
  country = 'issuing_country'
}

interface DocumentType {
  display_name: string
  id: string
  backside?: boolean
}

interface DocumentGroups {
  groups: {
    primary_ids: DocumentsGroup[]
    secondary_ids: DocumentsGroup[]
  }
  documents: DocumentType[]
}

export interface DocumentsGroup {
  group_id: string
  display_name: string
  verification_types: string[]
  ssn: boolean
  non_ssn: boolean
  documents: DocumentType[]
  issuing_entity_type: IssuingEntityType
}

export interface DocumentsState {
  groupsTypes: DocumentGroups
  groupsTypesStatus: RequestStatus
  groupsTypesError: RequestError | undefined
}

const documentsInitialState: DocumentsState = {
  groupsTypes: {
    groups: {
      primary_ids: [],
      secondary_ids: []
    },
    documents: []
  },
  groupsTypesStatus: RequestStatus.Pending,
  groupsTypesError: undefined
}

export const fetchDocumentGroups = createAsyncThunk('documentTypes/fetchDocumentGroups', async () => {
  const response: { data: DocumentGroups } = await apiClient.get('mail-authorization/documents/grouped_types')
  return response.data
})

export const documentSlice = createSlice({
  name: 'documentTypes',
  initialState: documentsInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDocumentGroups.fulfilled, (state, action) => {
        state.groupsTypesStatus = RequestStatus.Success
        state.groupsTypes = action.payload
        state.groupsTypesError = undefined
      })
      .addCase(fetchDocumentGroups.pending, state => {
        state.groupsTypesStatus = RequestStatus.Pending
      })
      .addCase(fetchDocumentGroups.rejected, (state, action) => {
        state.groupsTypesStatus = RequestStatus.Error
        state.groupsTypes = documentsInitialState.groupsTypes
        state.groupsTypesError = action.error.message
      })
  }
})

export const getDocument =
  (documentTypeId?: string) =>
  (state: RootState): DocumentType | undefined => {
    if (!documentTypeId) return
    return state.documentTypes.groupsTypes.documents.find(({ id }) => id === documentTypeId)
  }
export const getPrimaryGroupItems =
  (hasSsn: boolean) =>
  (state: RootState): DocumentsGroup[] =>
    state.documentTypes.groupsTypes.groups.primary_ids.filter(({ ssn, non_ssn }) => (hasSsn ? ssn : non_ssn)) // eslint-disable-line @typescript-eslint/naming-convention
export const getSecondaryGroupItems =
  (hasSsn: boolean) =>
  (state: RootState): DocumentsGroup[] =>
    state.documentTypes.groupsTypes.groups.secondary_ids.filter(({ ssn, non_ssn }) => (hasSsn ? ssn : non_ssn)) // eslint-disable-line @typescript-eslint/naming-convention
export const getDocumentGroupsReady = (state: RootState): boolean =>
  state.documentTypes.groupsTypesStatus === RequestStatus.Success
export const getIssuingEntityType =
  (documentTypeId?: string) =>
  (state: RootState): IssuingEntityType | undefined =>
    state.documentTypes.groupsTypes.groups.primary_ids.find(
      ({ documents, issuing_entity_type }) =>
        [IssuingEntityType.country, IssuingEntityType.state].includes(issuing_entity_type) &&
        documents.some(({ id }) => id === documentTypeId)
    )?.issuing_entity_type

export default documentSlice.reducer
