import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { InboxId } from 'interfaces/inbox.interface'
import { AddressesList, Address } from 'interfaces/address.interface'
import { AccountId } from 'interfaces/account.interface'

interface AddressToReauthorize {
  address: {
    address_line_1: string
    address_line_2?: string
    city: string
    state: string
    zip_code: string
    country: string
  } | null
  deadline_at: string | null
  business_recipient_count: number
  personal_recipient_count: number
  pmb: string
}

export interface AddressesState {
  addresses: Address[]
  addressesStatus: RequestStatus
  addressesError: RequestError | undefined
  addressToReauthorize: AddressToReauthorize
  addressToReauthorizeStatus: RequestStatus
  addressToReauthorizeError: RequestError | undefined
}

const initialState: AddressesState = {
  addresses: [],
  addressesStatus: RequestStatus.Pending,
  addressesError: undefined,
  addressToReauthorize: {
    address: null,
    deadline_at: null,
    business_recipient_count: 0,
    personal_recipient_count: 0,
    pmb: ''
  },
  addressToReauthorizeStatus: RequestStatus.Pending,
  addressToReauthorizeError: undefined
}

export const fetchInboxAddresses = createAsyncThunk('addresses/fetchAddresses', async (inboxId: InboxId) => {
  const response: { data: AddressesList } = await apiClient.get(`inboxes/${inboxId}/addresses`)
  return response.data.data
})

export const fetchInboxAddressToReauthorize = createAsyncThunk(
  'addresses/fetchInboxAddressesToReauthorize',
  async (accountId: AccountId) => {
    const response: { data: AddressToReauthorize } = await apiClient.get(
      `mail-authorization/accounts/${String(accountId)}/reverification-deadline`
    )
    return response.data
  }
)

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    resetAddressesState: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInboxAddresses.fulfilled, (state, action) => {
        state.addressesStatus = RequestStatus.Success
        state.addresses = action.payload
        state.addressesError = undefined
      })
      .addCase(fetchInboxAddresses.pending, state => {
        state.addressesStatus = RequestStatus.Pending
      })
      .addCase(fetchInboxAddresses.rejected, (state, action) => {
        state.addressesStatus = RequestStatus.Error
        state.addresses = initialState.addresses
        state.addressesError = action.error.message
      })
      .addCase(fetchInboxAddressToReauthorize.fulfilled, (state, action) => {
        state.addressToReauthorizeStatus = RequestStatus.Success
        state.addressToReauthorize = action.payload
        state.addressToReauthorizeError = undefined
      })
      .addCase(fetchInboxAddressToReauthorize.pending, state => {
        state.addressToReauthorizeStatus = RequestStatus.Pending
      })
      .addCase(fetchInboxAddressToReauthorize.rejected, (state, action) => {
        state.addressToReauthorizeStatus = RequestStatus.Error
        state.addressToReauthorize = initialState.addressToReauthorize
        state.addressToReauthorizeError = action.error.message
      })
  }
})

export default addressesSlice.reducer

export const { resetAddressesState } = addressesSlice.actions
export const getRecentAddresses = (state: RootState): Address[] =>
  state.addresses.addresses
    .slice()
    .filter((address: Address) => address.deleted_at === null)
    .sort((a: Address, b: Address) => a.locality.localeCompare(b.locality))
export const isAddressesReady = (state: RootState): boolean => state.addresses.addressesStatus === RequestStatus.Success
export const getAddress =
  (addressId: string) =>
  (state: RootState): Address | undefined =>
    state.addresses.addresses.find(({ address_id }) => address_id === addressId) // eslint-disable-line @typescript-eslint/naming-convention
export const getAddressToReauthorize = (state: RootState): AddressToReauthorize => state.addresses.addressToReauthorize
export const isAddressToReauthorizeReady = (state: RootState): boolean =>
  state.addresses.addressToReauthorizeStatus === RequestStatus.Success
