import jwt_decode from 'jwt-decode'
import { Auth } from 'aws-amplify'
import { AccessToken, Credentials, StoredCredentials, JsonWebToken } from 'interfaces/credential.interface'

const CREDENTIALS_KEY = 'earthclassmail.access_token'
const LAST_ACTION_KEY = 'earthclassmail.LastAction'
const IS_INBOUND_KEY = 'earthclassmail.is_inbound'
const PREFERENCES_KEY = 'earthclassmail.preferences'
const FIVE_MINUTES = 5 * 60 * 1000

const getStoredCredentials = (): StoredCredentials | null => {
  const accessToken = localStorage.getItem(CREDENTIALS_KEY)
  if (accessToken == null) return null
  return JSON.parse(accessToken)
}

export const buildStoredCredentials = (credentials: Credentials): StoredCredentials => {
  const decodedJWT: JsonWebToken = jwt_decode(credentials.access_token)
  const expiresAt = decodedJWT.exp
  const storedCredentials: StoredCredentials = { ...credentials, jwt: decodedJWT, expires_at: expiresAt }
  return storedCredentials
}

export const setStoredCredentials = (credentials: Credentials): void => {
  const storedCredentials = buildStoredCredentials(credentials)
  const parsedStoredCredentials = JSON.stringify(storedCredentials)
  localStorage.setItem(CREDENTIALS_KEY, parsedStoredCredentials)
  localStorage.setItem(LAST_ACTION_KEY, new Date().getTime().toString())
}

export const clearStoredCredentials = (): void => {
  localStorage.removeItem(CREDENTIALS_KEY)
  localStorage.removeItem(LAST_ACTION_KEY)
  localStorage.removeItem(IS_INBOUND_KEY)
  localStorage.removeItem(PREFERENCES_KEY)
}

export const getOrRefreshAccessTokenWithCognitoOrStoredCredentials = async (): Promise<AccessToken | string> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    return cognitoUser.signInUserSession.idToken.jwtToken
  } catch (e) {
    // Missing user in cognito, try Doorkeeper to get refreshToken
    try {
      const oldRefreshToken = await getOrRefreshAccessTokenWithStoredCredentials()
      return oldRefreshToken
    } catch (e) {
      // Missing also user in Doorkeeper, throw error
      console.log(e)
      throw e
    }
  }
}

const getOrRefreshAccessTokenWithStoredCredentials = async (): Promise<AccessToken | string> => {
  const storedCredentials: StoredCredentials | null = getStoredCredentials()
  if (storedCredentials == null) throw new Error('AccessTokenMissing')
  const expiresAt = storedCredentials.expires_at * 1000
  const fiveMinutesFromNow = new Date().getTime() + FIVE_MINUTES
  if (expiresAt > fiveMinutesFromNow) return storedCredentials.access_token
  throw new Error('ExpiredTokenRefresh')
}

export const areCredentialsValid = function (credentials: StoredCredentials): boolean {
  const deadlineWithTolerance = Math.floor(new Date().getTime() / 1000) - -10 * 60
  return (
    credentials.access_token != null &&
    credentials.token_type != null &&
    credentials.expires_at != null &&
    credentials.expires_at > deadlineWithTolerance
  )
}
