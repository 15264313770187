import { useSelector } from 'react-redux'
import amplitude, { Config } from 'amplitude-js'
import { getCurrentInbox } from 'store/inboxes'
import { amplitudeApiKey } from 'config/config'
import { User } from 'interfaces/user.interface'
import { AmplitudeEvent, amplitudeEvents } from '../interfaces/amplitude_service.interface'

declare global {
  interface Window {
    amplitude: Config
    amplitudeServiceInitialized: boolean
  }
}

interface AmplitudeServiceHook {
  initAmplitude: () => void
  identifyUser: (currentUser: User, isLZUser: boolean) => void
  logEvent: (eventName: AmplitudeEvent, eventProperties?: object) => void
  logEventWithinInbox: (eventName: AmplitudeEvent, eventProperties?: object) => void
  isValidEvent: (eventName: string) => eventName is AmplitudeEvent
}

const useAmplitudeService = (): AmplitudeServiceHook => {
  const currentInbox = useSelector(getCurrentInbox)

  const initAmplitude = (): void => {
    if (window.amplitudeServiceInitialized) {
      return
    }
    window.amplitude = amplitude
    if (amplitudeApiKey != null) {
      amplitude.getInstance().init(amplitudeApiKey)
      window.amplitudeServiceInitialized = true
    }
  }

  const identifyUser = (currentUser: User, isLZUser: boolean): void => {
    if (!window.amplitudeServiceInitialized) return

    amplitude.getInstance().setUserId(String(currentUser.id))
    const userProperties = {
      createdAt: currentUser.created_at,
      email: currentUser.email,
      firstName: currentUser.first_name,
      id: currentUser.id,
      isAdmin: currentUser.is_admin,
      lastName: currentUser.last_name,
      phone: currentUser.phone,
      username: currentUser.username,
      isLZUser
    }
    amplitude.getInstance().setUserProperties(userProperties)
  }

  const logEvent = (eventName: AmplitudeEvent, eventProperties?: object): void => {
    if (!window.amplitudeServiceInitialized) return

    if (eventProperties == null) {
      amplitude.getInstance().logEvent(eventName)
    } else {
      amplitude.getInstance().logEvent(eventName, eventProperties)
    }
  }

  const logEventWithinInbox = (eventName: AmplitudeEvent, eventProperties?: object): void => {
    if (!window.amplitudeServiceInitialized) {
      return
    }
    if (currentInbox === null) {
      logEvent(eventName, eventProperties)
      return
    }
    const account = currentInbox.account
    if (eventProperties == null) eventProperties = {}
    const eventAndAccountProperties = {
      ...eventProperties,
      ecmNumbers: account.ecm_numbers,
      accountId: account.id,
      inboxId: currentInbox.id
    }
    amplitude.getInstance().logEvent(eventName, eventAndAccountProperties)
  }

  const isValidEvent = (eventName: string): eventName is AmplitudeEvent => {
    return amplitudeEvents.includes(eventName as AmplitudeEvent)
  }

  return {
    initAmplitude,
    identifyUser,
    logEvent,
    logEventWithinInbox,
    isValidEvent
  }
}

export default useAmplitudeService
