import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { RootState } from 'store/store'
import { CurrentUserInfo } from 'interfaces/settings_user.interface'
import { IdpProviders, RequestError, RequestStatus } from 'interfaces/common.interface'

export interface AuthCurrentInfoState {
  authCurrentUserInfo: CurrentUserInfo | null
  status: RequestStatus
  error: RequestError | null
}

const initialState: AuthCurrentInfoState = {
  status: RequestStatus.Pending,
  authCurrentUserInfo: null,
  error: null
}

export const providerNameFromIdentities = (identities: string | undefined): string | undefined => {
  if (identities === undefined) return
  const parsedIdentities = JSON.parse(identities) as Array<{ providerName?: string }>
  if (parsedIdentities.length === 0) return
  return parsedIdentities[0].providerName === 'SignInWithApple'
    ? IdpProviders.Apple
    : (parsedIdentities[0].providerName as IdpProviders)
}

export const fetchAuthCurrentUserInfo = createAsyncThunk('authCurrentUserInfo/fetchAuthCurrentUserInfo', async () => {
  const response: CurrentUserInfo = await Auth.currentUserInfo()
  return response
})

export const authCurrentUserInfoSlice = createSlice({
  name: 'authCurrentUserInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAuthCurrentUserInfo.fulfilled, (state, action) => {
      state.authCurrentUserInfo = action.payload
      state.status = RequestStatus.Success
    })
  }
})

export const getIsLZUser = (state: RootState): boolean =>
  providerNameFromIdentities(state.authCurrentUserInfo.authCurrentUserInfo?.attributes?.identities) ===
  IdpProviders.LegalZoom
export const getIsAuthCurrentUserInfoReady = (state: RootState): boolean =>
  state.authCurrentUserInfo.status === RequestStatus.Success

export default authCurrentUserInfoSlice.reducer
