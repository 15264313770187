import { Container, Grid, useTheme, useMediaQuery } from '@mui/material'
import classNames from 'classnames'
import { pointInProgressIcon } from 'storybook/legalzoom/components/core/icons/get_started_icons'
import typography from 'styles/modules/lz_negroni_typography.module.scss'
import styles from './GetStartedSkeleton.module.scss'

const GetStartedSkeleton = (): JSX.Element => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid
      container
      flexDirection='column'
      flex={1}
      className={styles.getStartedApp}
      py={isMdUp ? 3 : 2}
      px={isMdUp ? 7 : 1}
    >
      <Container maxWidth='xl' className={styles.container}>
        <Grid container className={styles.top}>
          <Grid container flexDirection='column' item xs={12} md={3} className={styles.linkPlaceholder} />
          <Grid
            container
            flexDirection='column'
            item
            xs={12}
            md={9}
            className={classNames(typography.highlight1, styles.heading)}
          >
            Activate your virtual mail service today!
          </Grid>
        </Grid>
        <Grid container flexWrap={{ md: 'nowrap', xs: 'wrap' }}>
          {isMdUp && (
            <Grid container item xs={12} md={3} pr={4} height='530px'>
              <Grid container alignItems='start' mb={2} position='relative' flexWrap='nowrap'>
                <div className={styles.line} />
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
                <div className={styles.navigation}>
                  <div className={styles.icon} />
                  <div className={styles.text} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                </div>
              </Grid>
              <Grid container alignItems='start' position='relative' flexWrap='nowrap'>
                <div className={styles.line} />
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
                <div className={styles.navigation}>
                  <div className={styles.icon} />
                  <div className={styles.text} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                </div>
              </Grid>
              <Grid container alignItems='start' flexWrap='nowrap'>
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
                <div className={styles.navigation}>
                  <div className={styles.icon} />
                  <div className={styles.text} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                  <div className={styles.description} />
                </div>
              </Grid>
            </Grid>
          )}
          {!isMdUp && (
            <Grid container item xs={12} pb={4} className={styles.mobileNavigation} color='white'>
              <Grid container item xs={4} justifyContent='center' className={styles.horizontalLine}>
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
              </Grid>
              <Grid container item xs={4} justifyContent='center'>
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
              </Grid>
              <Grid container item xs={4} justifyContent='center'>
                <img className={styles.point} src={pointInProgressIcon} alt='point' />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                alignItems='end'
                className={styles.mobileNavigationText}
              >
                <div className={styles.icon} />
                <div className={styles.text} />
              </Grid>
            </Grid>
          )}
          <Grid container className={styles.mainContent} item xs={12} md={9} wrap='nowrap'>
            <Grid container item xs='auto' justifyContent='center' pb={isMdUp ? 0 : 2} pr={isMdUp ? 0 : 2}>
              <div className={styles.bigIcon} />
            </Grid>
            <Grid container pl={isMdUp ? 4 : 0}>
              <Grid container item flexDirection='column'>
                <div className={styles.mainTitle} />
                <div className={styles.mainDescription} />
                <div className={styles.mainDescription} />
                <div className={styles.mainDescription} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default GetStartedSkeleton
