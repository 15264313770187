import { useDispatch } from 'react-redux'
import { configureStore, ThunkAction, Action, AnyAction, Reducer } from '@reduxjs/toolkit'
import { CombinedState, combineReducers } from 'redux'
import shipmentsReducer, { ShipmentsState } from 'apps/ShipmentsApp/store'
import getStartedReducer, { GetStartedState } from 'apps/GetStartedApp/store'
import dashboardAppReducer, { DashboardState } from 'apps/DashboardApp/store/index'
import settingsReducer, { SettingsState } from 'apps/SettingsApp/store/index'
import mailroomReducer, { MailroomState } from 'apps/MailroomApp/store/index'
import currentUserReducer, { CurrentUserState } from './current_user'
import authCurrentUserReducer, { AuthCurrentInfoState } from './auth_user_info'
import inboxesReducer, { InboxesState } from './inboxes'
import uiStoreReducer, { UiState } from './ui_store'
import applicantReducer, { ApplicantState } from './applicant'
import recipientsReducer, { RecipientsState } from './recipients'
import shippingAddressesReducer, { ShippingAdressesState } from './shared/shipping_addresses'
import countriesReducer, { CountriesState } from './shared/countries'
import recipientsBillingInfoReducer, { RecipientsBillingInfoState } from './shared/recipients_billing_info'
import addressesReducer, { AddressesState } from './shared/addresses'
import applicantsReducer, { ApplicantsState } from './shared/applicants'
import accountCancellationReducer, { AccountCancellationState } from './shared/account_cancellation'
import documentTypesReducer, { DocumentsState } from './shared/document_types'
import automatedVerificationReducer, { AutomatedVerificationState } from './shared/automated_verification'

export const GENERAL_LOGOUT_ACTION = 'general/logout'

type CombinedReducersType = CombinedState<{
  currentUser: CurrentUserState
  authCurrentUserInfo: AuthCurrentInfoState
  inboxes: InboxesState
  dashboardApp: DashboardState
  uiStore: UiState
  settingsApp: SettingsState
  mailroomApp: MailroomState
  shipmentsApp: ShipmentsState
  applicant: ApplicantState
  recipients: RecipientsState
  sharedShippingAddresses: ShippingAdressesState
  countries: CountriesState
  getStartedApp: GetStartedState
  recipientsBillingInfo: RecipientsBillingInfoState
  addresses: AddressesState
  applicants: ApplicantsState
  accountCancellation: AccountCancellationState
  documentTypes: DocumentsState
  automatedVerification: AutomatedVerificationState
}>

const combinedReducer: Reducer<CombinedReducersType, AnyAction> = combineReducers({
  currentUser: currentUserReducer,
  authCurrentUserInfo: authCurrentUserReducer,
  inboxes: inboxesReducer,
  dashboardApp: dashboardAppReducer,
  uiStore: uiStoreReducer,
  settingsApp: settingsReducer,
  mailroomApp: mailroomReducer,
  shipmentsApp: shipmentsReducer,
  applicant: applicantReducer,
  recipients: recipientsReducer,
  sharedShippingAddresses: shippingAddressesReducer,
  countries: countriesReducer,
  getStartedApp: getStartedReducer,
  recipientsBillingInfo: recipientsBillingInfoReducer,
  addresses: addressesReducer,
  applicants: applicantsReducer,
  accountCancellation: accountCancellationReducer,
  documentTypes: documentTypesReducer,
  automatedVerification: automatedVerificationReducer
})

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === GENERAL_LOGOUT_ACTION) {
    state = <RootState>{} // eslint-disable-line @typescript-eslint/consistent-type-assertions
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof combinedReducer>
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>() // eslint-disable-line @typescript-eslint/explicit-function-return-type
