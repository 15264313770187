import { Suspense, useEffect, lazy, useState } from 'react'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hotjar } from 'react-hotjar'
import { Auth } from 'aws-amplify'
import { getCurrentUser } from 'store/current_user'
import {
  fetchCurrentInbox,
  getCurrentInbox,
  fetchInboxesList,
  getInboxesList,
  getActiveInboxes,
  isInboxesListReady
} from 'store/inboxes'
import { fetchAuthCurrentUserInfo, getIsAuthCurrentUserInfoReady, getIsLZUser } from 'store/auth_user_info'
import { AngularConfig, getAngularConfig } from 'config/envconfig'
import { hotjarId, hotjarSnippetVersion } from 'config/config'
import { setPreference } from 'utils/preferences_service'
import { apiClient } from 'utils/api'
import isInboxAvailableForLogin from 'utils/is_inbox_available_for_login'
import { UserContext } from 'hooks/useUserContext'
import useUserCredentials from 'hooks/useUserCredentials'
import { AlertContext } from 'hooks/useAlertContext'
import useAmplitudeService from 'hooks/useAmplitudeService'
import useZendeskService from 'hooks/useZendeskService'
import useLaunchDarklyInit from 'hooks/useLaunchDarklyInit'
import { AccountStatus, RecipientsVersion } from 'interfaces/account.interface'
import { InboxWithFlags } from 'interfaces/inbox.interface'
import { IdpProviders } from 'interfaces/common.interface'
import NarrowNavigationSkeleton from 'storybook/components/molecules/skeletons/Navigation/NarrowNavigationSkeleton'
import ExtendedNavigationSkeleton from 'storybook/components/molecules/skeletons/Navigation/ExtendedNavigationSkeleton'
import Alert, { ALERT_INITIAL_STATE } from 'storybook/components/atoms/alerts/Alert'
import AppSkeleton from 'storybook/components/organisms/skeletons/AppSkeleton'
import GetStartedSkeleton from 'apps/GetStartedApp/components/skeletons/GetStartedSkeleton/GetStartedSkeleton'
import { PendingStatements } from 'apps/ReactivationApp/reactivation.interface'
import { sanitizeRedirectPath } from 'apps/AuthenticationApp/utils/auth'

const AngularComponent = lazy(async () => await import('components/AngularComponent'))
const AuthenticationApp = lazy(async () => await import('apps/AuthenticationApp/AuthenticationApp'))
const InboxApp = lazy(async () => await import('apps/InboxApp/InboxApp'))
const ShipmentsApp = lazy(async () => await import('apps/ShipmentsApp/ShipmentsApp'))
const DashboardApp = lazy(async () => await import('apps/DashboardApp/DashboardApp'))
const SettingsApp = lazy(async () => await import('apps/SettingsApp/SettingsApp'))
const GetStartedApp = lazy(async () => await import('apps/GetStartedApp/GetStartedApp'))
const SuspendedApp = lazy(async () => await import('apps/SuspendedApp/SuspendedApp'))
const ReactivationApp = lazy(async () => await import('apps/ReactivationApp/ReactivationApp'))

declare global {
  interface Window {
    itemCountChange: () => void
    getAngularConfig: () => AngularConfig
  }
}

const App = (): JSX.Element => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isUserLoading } = useUserCredentials()
  const inboxesListReady = useSelector(isInboxesListReady)
  const activeInboxes = useSelector(getActiveInboxes)
  const inboxesList = useSelector(getInboxesList)
  const currentUser = useSelector(getCurrentUser)
  const currentInbox = useSelector(getCurrentInbox)
  const isLegalZoomUser = useSelector(getIsLZUser)
  const isAuthCurrentUserInfoReady = useSelector(getIsAuthCurrentUserInfoReady)
  const [alertValue, setAlert] = useState(ALERT_INITIAL_STATE)
  const { initAmplitude, identifyUser } = useAmplitudeService()
  const { initZendesk } = useZendeskService()

  useLaunchDarklyInit()

  window.getAngularConfig = getAngularConfig

  const lzRedirectPath = (): string => {
    if (pathname.includes('/lz/get-started')) {
      const splittedPathname = pathname.split('/lz/get-started')
      return [splittedPathname[0], '/continue-verification', splittedPathname[1], search].join('')
    }
    const safeRedirectPath = sanitizeRedirectPath([...pathname.split('/lz'), search].join(''))
    return safeRedirectPath != null ? safeRedirectPath : '/'
  }

  useEffect(() => {
    initAmplitude()
    if (hotjarId != null) hotjar.initialize(parseInt(hotjarId), parseInt(hotjarSnippetVersion))
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isUserLoading || currentUser != null) return
    if (pathname.includes('/lz/')) {
      Auth.federatedSignIn({ customProvider: IdpProviders.LegalZoom, customState: `lzRedirect:${lzRedirectPath()}` }) // eslint-disable-line @typescript-eslint/no-floating-promises
      localStorage.setItem('idpProvider', IdpProviders.LegalZoom)
      return
    }
    if (['/login', '/password-setup', '/reset-temp-password', '/logout'].includes(pathname)) return
    if (pathname === '/') {
      navigate('/login')
      return
    }
    const safeRedirectPath = sanitizeRedirectPath(`${pathname}${search}`)
    navigate(`/login?redirect_url=${safeRedirectPath}`)
  }, [isUserLoading, currentUser, pathname, search, navigate]) // eslint-disable-line

  useEffect(() => {
    if (currentUser == null || !pathname.includes('/lz/')) return
    navigate(lzRedirectPath())
  }, [currentUser, pathname, search]) // eslint-disable-line

  useEffect(() => {
    if (currentUser == null) return
    dispatch(fetchAuthCurrentUserInfo())
    dispatch(fetchInboxesList(currentUser.id))
  }, [currentUser, dispatch]) // eslint-disable-line

  useEffect(() => {
    if (currentUser == null || !isAuthCurrentUserInfoReady) return
    identifyUser(currentUser, isLegalZoomUser)
  }, [currentUser, isAuthCurrentUserInfoReady]) // eslint-disable-line

  useEffect(() => {
    if (!inboxesListReady) return
    if (currentUser == null) return
    if (
      (currentInbox && !isInboxAvailableForLogin(currentInbox)) ||
      [
        '/',
        '/login',
        '/password-setup',
        '/reset-temp-password',
        '/dashboard',
        '/continue-verification',
        '/cancel-account',
        '/update-payment-details',
        '/update-credit-card',
        '/reactivate-account'
      ].includes(pathname)
    ) {
      const activeInbox = activeInboxes[0]
      if (activeInbox != null) {
        if (search.includes('twoFactorAuthenticationDeactivated')) {
          navigate(`/inboxes/${activeInbox.id}/dashboard?twoFactorAuthenticationDeactivated=true`)
        } else if (pathname.includes('/continue-verification')) {
          navigate(`/get-started/${activeInbox.id}`)
        } else if (pathname.includes('/cancel-account')) {
          navigate(`/settings/${activeInbox.id}/account-management/billing?openCancelationDialog=true`)
        } else if (pathname.includes('/update-credit-card')) {
          navigate(`/settings/${activeInbox.id}/payment-details`)
        } else {
          navigate(`/inboxes/${activeInbox.id}/dashboard`)
        }
      } else {
        const firstSuspendedInbox = inboxesList.find(
          ({ account }) => account.account_status === AccountStatus.Suspended
        )
        const closedInboxes = inboxesList.filter(({ account }) => account.account_status === AccountStatus.Closed)
        if (firstSuspendedInbox != null) {
          navigate(`/update-payment-details/${firstSuspendedInbox.id}`)
        } else if (closedInboxes.length > 0) {
          const inboxEligibleForReactivation = closedInboxes.find(({ account }) => account.is_eligible_for_reactivation)
          if (inboxEligibleForReactivation != null) {
            apiClient
              .get(`accounts/${inboxEligibleForReactivation.account.id}/billing/pending-statements`)
              .then(({ data }: { data: PendingStatements }) => {
                if (data.statements.length > 0) {
                  navigate(`/reactivate-account/${inboxEligibleForReactivation.id}`)
                } else {
                  navigate('/logout?isEligibleForReactivation=false')
                }
              })
              .catch(() => {
                navigate('/logout?isEligibleForReactivation=false')
              })
          } else {
            navigate('/logout?isEligibleForReactivation=false')
          }
        } else {
          navigate('/logout?noInboxes=true')
        }
      }
    }
  }, [currentUser, currentInbox, activeInboxes, inboxesList, inboxesListReady, pathname, search, navigate])

  useEffect(() => {
    const inboxIdMatches = pathname.match(
      /\/(inboxes|settings|get-started|update-payment-details|reactivate-account)\/(?<inboxId>\d+)(.*)?/
    )
    const inboxId = inboxIdMatches?.groups?.inboxId
    if (inboxId == null) return
    if (currentInbox == null || String(currentInbox.id) !== inboxId) {
      dispatch(fetchCurrentInbox(inboxId))
    }
  }, [pathname, currentInbox, dispatch])

  useEffect(() => {
    if (isUserLoading || currentUser == null) return
    setPreference('current_user', currentUser)
  }, [isUserLoading, currentUser])

  useEffect(() => {
    if (isUserLoading || currentInbox == null || currentUser == null) return
    setPreference('current_inbox', currentInbox)
    initZendesk()
  }, [isUserLoading, currentInbox, currentUser]) // eslint-disable-line

  const fallbackSkeleton = (): JSX.Element => {
    if (pathname === '/') return <div />
    if (pathname.includes('reactivate-account')) return <div />
    if (pathname.includes('update-payment-details')) return <div />
    if (pathname.includes('get-started')) {
      if (isAuthCurrentUserInfoReady) {
        return <GetStartedSkeleton />
      }
      return <div />
    }
    if (pathname.includes('dashboard')) return <ExtendedNavigationSkeleton />
    return <NarrowNavigationSkeleton />
  }

  const getCurrentInboxForContext = (): InboxWithFlags => {
    if (currentInbox != null) {
      const {
        get_started_completed,
        get_started_account_automation_completed,
        automation_settings_migrated,
        recipients_version
      } = currentInbox.account // eslint-disable-line @typescript-eslint/naming-convention
      const hasNewRecipients = [RecipientsVersion.V1, RecipientsVersion.V15, RecipientsVersion.V2].includes(
        recipients_version
      )
      return {
        ...currentInbox,
        showGetStarted: hasNewRecipients && !get_started_completed,
        getStartedAccountAutomationCompleted: get_started_account_automation_completed,
        hasAccountAutomation: automation_settings_migrated,
        hasNewRecipients,
        recipientsVersion: recipients_version
      }
    }
    return {
      ...inboxesList[0],
      showGetStarted: false,
      getStartedAccountAutomationCompleted: false,
      hasAccountAutomation: false,
      hasNewRecipients: false,
      recipientsVersion: RecipientsVersion.V0
    }
  }

  window.itemCountChange = (): void => {
    if (currentUser == null) return
    dispatch(fetchInboxesList(currentUser.id))
  }

  if (currentUser == null && !isUserLoading) {
    return (
      <>
        <Alert setAlert={setAlert} {...alertValue} />
        <Suspense fallback={<div />}>
          <AlertContext.Provider value={{ alertValue, setAlert }}>
            <AuthenticationApp />
          </AlertContext.Provider>
        </Suspense>
      </>
    )
  }

  if (currentUser == null) return <AppSkeleton />

  if (currentInbox == null && !pathname.includes('/bill-dot-com/')) return fallbackSkeleton()

  if (
    currentInbox?.account.account_status === AccountStatus.Closed &&
    currentInbox?.account.is_eligible_for_reactivation
  ) {
    return (
      <>
        <Alert setAlert={setAlert} {...alertValue} />
        <UserContext.Provider value={{ currentUser, currentInbox: getCurrentInboxForContext() }}>
          <Suspense fallback={<div />}>
            <AlertContext.Provider value={{ alertValue, setAlert }}>
              <Routes>
                <Route path='/reactivate-account/:inboxId' element={<ReactivationApp />} />
                <Route path='*' element={<Navigate to={`/reactivate-account/${currentInbox.id}`} replace />} />
              </Routes>
            </AlertContext.Provider>
          </Suspense>
        </UserContext.Provider>
      </>
    )
  }

  if (currentInbox?.account.account_status === AccountStatus.Suspended) {
    return (
      <>
        <Alert setAlert={setAlert} {...alertValue} />
        <UserContext.Provider value={{ currentUser, currentInbox: getCurrentInboxForContext() }}>
          <Suspense fallback={<div />}>
            <AlertContext.Provider value={{ alertValue, setAlert }}>
              <Routes>
                <Route path='/update-payment-details/:inboxId' element={<SuspendedApp />} />
                <Route path='*' element={<Navigate to={`/update-payment-details/${currentInbox.id}`} replace />} />
              </Routes>
            </AlertContext.Provider>
          </Suspense>
        </UserContext.Provider>
      </>
    )
  }

  return (
    <>
      <Alert setAlert={setAlert} {...alertValue} />
      <UserContext.Provider value={{ currentUser, currentInbox: getCurrentInboxForContext() }}>
        <Suspense fallback={fallbackSkeleton()}>
          <AlertContext.Provider value={{ alertValue, setAlert }}>
            <Routes>
              <Route path='/inboxes/:inboxId/dashboard' element={<DashboardApp />} />
              <Route path='/inboxes/:inboxId/shipments' element={<ShipmentsApp />} />
              <Route path='/get-started/:inboxId/*' element={<GetStartedApp />} />
              <Route path='/inboxes/:inboxId/*' element={<InboxApp />} />
              <Route path='/settings/:inboxId/*' element={<SettingsApp />} />
              <Route path='/bill-dot-com/*' element={<AngularComponent />} />
            </Routes>
          </AlertContext.Provider>
        </Suspense>
      </UserContext.Provider>
    </>
  )
}

export default App
