export enum ApplicantStatus {
  InProgress = 'in_progress',
  Unverified = 'unverified',
  Verified = 'verified'
}

export interface PrimaryIdDetails {
  type?: string
  id_number?: string
  issuing_state?: string
  issuing_country?: string
  expiration_date?: string | null
}

export interface ApplicantHomeAddress {
  address_line_1: string
  address_line_2?: string
  city: string
  state: string
  zip_code: string
  country: string
}

export interface Applicant {
  full_name?: string
  first_name: string
  last_name: string
  middle_initial?: string
  has_ssn: boolean
  primary_id?: string
  secondary_id?: string
  primary_id_document?: PrimaryIdDetails
  secondary_id_document?: { type: string }
  home_address: ApplicantHomeAddress
  phone_number: string
  status?: ApplicantStatus
  created_at?: string
  updated_at?: string
  verified_at?: string
  user_email?: string
  flow_version: 'v1' | 'v2'
}

export interface ApplicantResponseBody extends Applicant {
  status: ApplicantStatus
  created_at: string
  updated_at: string
  verified_at: string
}

export interface ApplicantDocuments {
  id: string
  type: 'court_ordered_protection'
  document: {
    name: string
    uri: string
    type: string
  }
}
